import { defineStore } from 'pinia';
import { loadUser } from "@/network/wx/user.js";

const useUserStore = defineStore({
    id: 'user', 
    state: () => ({
        isLoaded: false,
        hasChanges: false,
        user: {
            user_id: '',
            mpid: '',
            wx_openid: '',
            wx_unionid: '',
            pid: '',
            real_name: '',
            hidden_name: true,
            former_name: '',
            wx_name: '',
            user_account: '',
            nick_name: '',
            user_avatar: '',
            user_sex: '',
            user_mail: '',
            user_phone: '',
            wx_account: '',
            user_status: '',
            user_type: '',
            note: ''
        }
    }),

    // 方法
    actions: {
        // 更新用户信息
        setUser() {
            loadUser({ wx_openid: window.localStorage.getItem('wx_openid') }).then((res) => {
                if(res){
                    res.hidden_name = res.hidden_name == 1;
                    Object.assign(this.user, res);
                }
            });
        }
    }
});

export {useUserStore};