const cslGameRoute = [
    {
        path: '/csl/game/cashout.htm',
        name: 'cslGamecashout',
        component: () => import('../../views/csl/game/cashout.vue'),
    },
    {
        path: '/csl/game/exchange.htm',
        name: 'cslGameexchange',
        component: () => import('../../views/csl/game/exchange.vue'),
    },
    {
        path: '/csl/game/video.htm',
        name: 'cslGameVideo',
        component: () => import('../../views/csl/game/video.vue'),
    }
]

export default cslGameRoute