const cslAttnRoute = [
    {
        path: '/csl/attn/punchs',
        name: 'cslAttnPunchs',
        component: () => import('../../views/csl/attn/punchs.vue'),
    },
    {
        path: '/csl/attn/schedule.htm',
        name: 'cslAttnSchedule',
        component: () => import('../../views/csl/attn/schedule.vue'),
    },
    {
        path: '/csl/attn/month.htm',
        name: 'cslAttnMonth',
        component: () => import('../../views/csl/attn/month.vue'),
    },
    {
        path: '/csl/attn/export.htm',
        name: 'cslAttnExport',
        component: () => import('../../views/csl/attn/export.vue'),
    },
    {
        path: '/csl/attn/hse.htm',
        name: 'cslAttnHse',
        component: () => import('../../views/csl/attn/hse.vue'),
    },
    {
        path: '/csl/attn/leave',
        name: 'cslAttnLeave',
        component: () => import('../../views/csl/attn/leave.vue'),
    },
    {
        path: '/csl/attn/upload.htm',
        name: 'cslAttnLeave',
        component: () => import('../../views/csl/attn/upload.vue'),
    }

]

export default cslAttnRoute