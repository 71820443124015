import axios from "axios";
import { useRouter } from 'vue-router';
import { wxToast } from "@/components/wxui/wx-toast/index.js";
import site from '/siteinfo.js';

export function request(config) {
    const instance = axios.create({
        baseURL: site.siteroot,
        timeout: 5000,
    })

    // 设置重试次数
    instance.defaults.retry = 3;
    // 设置重试间隔
    instance.defaults.retryDelay = 1000;

    // 携带mpid
    config.data = {
        mpid: site.mpid,
        wx_openid: window.localStorage.getItem('wx_openid'),
        ...config.data
    }

    // 处理get请求传参数
    if (config.data && config.method == 'get') {

        config.url = config.url + '?';
        for (let key in config.data) {
            config.url += key + '=' + config.data[key] + '&';
        }
        config.url = config.url.substr(0, config.url.length - 1);
    }

    // 请求拦截
    instance.interceptors.request.use(
        (config) => {
            // 发起网络请求时显示 “加载中”
            if (!config.unloading) {
                wxToast.loading();
            }

            // 如果API需要认证， 在这统一设置
            // const token = window.localStorage.getItem('token');

            // if(token){
            //     config.headers.Authorization = token;
            // }

            return config
        },
        (err) => {
            console.log(err);
        }
    );

    // 响应拦截
    instance.interceptors.response.use(
        (res) => {
            // console.log(res.data);
            // 后端判断 登录过期
            if(res.data.code <= 0){
                // 移除缓存
                window.localStorage.removeItem("wx_openid");
                // 刷新页面进行鉴权
                window.location.reload();
            }

            // 请求结束，隐藏加载
            wxToast.hidden();
            
            return res.data;
        },
        (err) => {

            if (!config.unloading) {
                wxToast.warn('网络异常');
            }
        }
    );

    return instance(config);
}