const gameRoute = [
    {
        path: '/game/packet.htm',
        name: 'gamePacket',
        meta: { isAuth: true, state: 1 },
        component: () => import('../../views/wx/game/packet.vue')
    },
    {
        path: '/game/cashout.htm',
        name: 'gameCashout',
        meta: { isAuth: true, state: 1 },
        component: () => import('../../views/wx/game/cashout.vue')
    },
    // {
    //     path: '/game/exchange.htm',
    //     name: 'gameExchange',
    //     // meta: { isAuth: true, state: 1 },
    //     component: () => import('../../views/wx/game/exchange.vue')
    // }
    {
        path: '/game/poster.htm',
        name: 'gamePoster',
        // meta: { isAuth: true, state: 1 },
        component: () => import('../../views/wx/game/poster.vue')
    },
]

export default gameRoute