
const attnRoute = [
    {
        path: '/attn/punchs.htm',
        name: 'attnPunchs',
        meta: { isAuth: true, state: 1},
        component: () => import('../../views/wx/attn/punchs.vue'),
    },
    {
        path: '/attn/daily.htm',
        name: 'attnDaily',
        // meta: { isAuth: true, state: 1},
        component: () => import('../../views/wx/attn/daily.vue'),
    },
    {

        path: '/attn/leave.htm',
        name: 'attnLeave',
        meta: { isAuth: true, state: 1},
        component: () => import('../../views/wx/attn/leave.vue'),
    },
    {
        path: '/attn/monly.htm',
        name: 'attnMonly',
        meta: { isAuth: true, state: 1},
        component: () => import('../../views/wx/attn/monly.vue'),
    }
]

export default attnRoute