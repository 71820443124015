import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store';
import { createPinia } from 'pinia'

import '@/components/wxui/css/wxui.css';
// import '@/assets/iconFont/iconfont.css';
import '@/components/wxui/css/iconfontOnline.css';

// 根据屏幕宽度设置跟元素字体大小
import { wd } from '@/components/wxui/window/index.js';
wd.setRootFontSize();

createApp(App).use(createPinia()).use(router).mount('#app')

// 初始化获取用户信息
import { useUserStore } from './store/user.js';
const userstore = useUserStore();
const wx_openid = window.localStorage.getItem('wx_openid');
if(wx_openid){
    userstore.setUser();
}


